import './_contacts.scss';
import Swiper from "swiper";
import {Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';


function project(latLng) {
    var TILE_SIZE = 256

    var siny = Math.sin(latLng.lat() * Math.PI / 180)

    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    siny = Math.min(Math.max(siny, -0.9999), 0.9999)

    return new google.maps.Point(
        TILE_SIZE * (0.5 + latLng.lng() / 360),
        TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)))
}

function getPixel(latLng, zoom) {
    var scale = 1 << zoom
    var worldCoordinate = project(latLng)
    return new google.maps.Point(
        Math.floor(worldCoordinate.x * scale),
        Math.floor(worldCoordinate.y * scale))
}

function getMapDimenInPixels(map) {
    var zoom = map.getZoom()
    var bounds = map.getBounds()
    var southWestPixel = getPixel(bounds.getSouthWest(), zoom)
    var northEastPixel = getPixel(bounds.getNorthEast(), zoom)
    return {
        width: Math.abs(southWestPixel.x - northEastPixel.x),
        height: Math.abs(southWestPixel.y - northEastPixel.y)
    }
}

function willAnimatePanTo(map, destLatLng, optionalZoomLevel) {
    var dimen = getMapDimenInPixels(map)

    var mapCenter = map.getCenter()
    optionalZoomLevel = !!optionalZoomLevel ? optionalZoomLevel : map.getZoom()

    var destPixel = getPixel(destLatLng, optionalZoomLevel)
    var mapPixel = getPixel(mapCenter, optionalZoomLevel)
    var diffX = Math.abs(destPixel.x - mapPixel.x)
    var diffY = Math.abs(destPixel.y - mapPixel.y)

    return diffX < dimen.width && diffY < dimen.height
}

function getOptimalZoomOut(map, latLng, currentZoom) {
    if (willAnimatePanTo(map, latLng, currentZoom - 1)) {
        return currentZoom - 1
    } else if (willAnimatePanTo(map, latLng, currentZoom - 2)) {
        return currentZoom - 2
    } else {
        return currentZoom - 3
    }
}

function smoothlyAnimatePanToWorkarround(map, destLatLng, optionalAnimationEndCallback) {
    var initialZoom = map.getZoom(), listener

    function zoomIn() {
        if (map.getZoom() < initialZoom) {
            map.setZoom(Math.min(map.getZoom() + 3, initialZoom))
        } else {
            google.maps.event.removeListener(listener)

            map.setOptions({draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: false})

            if (!!optionalAnimationEndCallback) {
                optionalAnimationEndCallback()
            }
        }
    }

    function zoomOut() {
        if (willAnimatePanTo(map, destLatLng)) {
            google.maps.event.removeListener(listener)
            listener = google.maps.event.addListener(map, 'idle', zoomIn)
            map.panTo(destLatLng)
        } else {
            map.setZoom(getOptimalZoomOut(map, destLatLng, map.getZoom()))
        }
    }

    map.setOptions({draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true})
    map.setZoom(getOptimalZoomOut(map, destLatLng, initialZoom))
    listener = google.maps.event.addListener(map, 'idle', zoomOut)
}

function smoothlyAnimatePanTo(map, destLatLng) {
    if (willAnimatePanTo(map, destLatLng)) {
        map.panTo(destLatLng)
    } else {
        smoothlyAnimatePanToWorkarround(map, destLatLng)
    }
}

const contacts = function () {
    const blocks = document.querySelectorAll('section.contacts');
    for (const block of blocks) {
        if (window.innerWidth <= 991) {
            if (block.querySelector('.swiper') !== null) {
                const swiper = new Swiper(block.querySelector('.swiper'), {
                    modules: [Pagination],
                    speed: 400,
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                    pagination: {
                        el: block.querySelector('.swiper-pagination'),
                        clickable: true
                    }
                });
            }
            if (block.querySelectorAll('.swiper-mobile').length !== 0) {
                block.querySelectorAll('.swiper-mobile').forEach(mobSwiper => {
                    const swiper = new Swiper(mobSwiper, {
                        modules: [Pagination],
                        speed: 400,
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                        pagination: {
                            el: mobSwiper.querySelector('.swiper-pagination'),
                            clickable: true
                        }
                    });
                });
            }
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth <= 991) {
                if (block.querySelector('.swiper') !== null) {
                    const swiper = new Swiper(block.querySelector('.swiper'), {
                        modules: [Pagination],
                        speed: 400,
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                        pagination: {
                            el: block.querySelector('.swiper-pagination'),
                            clickable: true
                        }
                    });
                }
                if (block.querySelectorAll('.swiper-mobile').length !== 0) {
                    block.querySelectorAll('.swiper-mobile').forEach(mobSwiper => {
                        const swiper = new Swiper(mobSwiper, {
                            modules: [Pagination],
                            speed: 400,
                            slidesPerView: 1.2,
                            spaceBetween: 20,
                            pagination: {
                                el: mobSwiper.querySelector('.swiper-pagination'),
                                clickable: true
                            }
                        });
                    });
                }
            }
        });
    }


    if (document.querySelectorAll('.maps').length !== 0) {
        window.initMap = function () {
            for (const block of blocks) {
                if (block.classList.contains('map_categories')) {
                    let markers = [];
                    let stylesArray = [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ];
                    let map = new google.maps.Map(block.querySelector('.maps'), {
                        zoom: 15,
                        disableDefaultUI: true,
                        styles: stylesArray
                    });

                    block.querySelectorAll('.contact').forEach(contact => {
                        let lng = contact.dataset.lng;
                        let lat = contact.dataset.lat;
                        let address = contact.dataset.address;
                        console.log(address)
                        if (!isNaN(parseFloat(lng)) && !isNaN(parseFloat(lat))) {
                            let myLatlng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
                            markers.push({position: myLatlng, address: address});
                            contact.addEventListener('click', () => {
                                block.querySelectorAll('.contact').forEach(allContacts => {
                                    allContacts.classList.remove('active');
                                });
                                setTimeout(() => {
                                    contact.classList.add('active');
                                }, 50);
                                smoothlyAnimatePanTo(map, myLatlng);
                            });
                        }
                    });

                    let uniquePositions = [];
                    for (let i = 0; i < markers.length; i++) {

                        // Check if the position is already added
                        if (!isPositionAlreadyAdded(markers[i].position, uniquePositions)) {
                            map.setCenter(markers[i].position);

                            // Add the position to the uniquePositions array
                            uniquePositions.push(markers[i].position);

                            let a = new google.maps.Marker({
                                position: markers[i].position,
                                icon: postmpwbpk.template_directory + '/src/images/icons/marker.svg',
                                map: map,
                            });

                            let linkURL = 'https://www.google.com/maps/search/?api=1&query=' + markers[i].address;
                            a.addListener('click', function () {
                                window.open(linkURL, '_blank');
                            });
                        }
                    }

                    block.querySelectorAll('.category-button').forEach(categoryButton => {
                        categoryButton.addEventListener('click', () => {
                            block.querySelectorAll('.category-button').forEach(btn => {
                                btn.classList.remove('active');
                            });
                            let list = categoryButton.dataset.list;
                            categoryButton.classList.add('active');
                            block.querySelectorAll('.right-side .list').forEach(listItem => {
                                if (listItem.dataset.list === list) {
                                    listItem.classList.add('active');
                                    const contacts = listItem.querySelectorAll('.contact');
                                    if (contacts.length > 0) {
                                        contacts[0].click();
                                    }
                                } else {
                                    listItem.classList.remove('active');
                                }
                            });
                        });
                    });
                }
            }
        }

        var script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDK7eB3mpPMCjWEm097qyJx0eqWTUdi-M0&callback=initMap';
        // script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB3UbrbPV2FEgQhWX183Lgnuovoo0C7HC0&callback=initMap';
        script.async = true;
        document.head.appendChild(script);
    }

    /**
     * isPositionAlreadyAdded()
     *
     * Function to check if a Google Maps position is already in the array.
     *
     * @param {google.maps.LatLng} position - The position to check.
     * @param {Array<google.maps.LatLng>} array - The array of positions to check against.
     * @returns {boolean} Returns true if the position is already in the array, otherwise false.
     */
    function isPositionAlreadyAdded(position, array) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].equals(position)) {
                return true;
            }
        }
        return false;
    }
};

document.addEventListener('DOMContentLoaded', function () {
    contacts()
}, false);

window.addEventListener('acf/loaded/contacts', function (e) {
    contacts()
});